import { HubConnection } from '@microsoft/signalr/dist/esm/HubConnection';
import React, { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { getTokenAsync } from '../firebase';
import { Department } from '../models/department';
import { Page } from '../models/page';
import { Provider } from '../models/provider';
import { QuickPage } from '../models/quickPage';
import { Reason } from '../models/reason';
import { Site } from '../models/site';
import { SyncAppData } from '../models/syncAppData';
import { UserInfo } from '../models/userInfo';
import { Api, pathJoin } from '../utils/api';
import { useIsMobile } from '../utils/hooks';
import { sortPages } from '../utils/pageSort';
import { changeTheme } from '../utils/themes';

export type AppContext = {
    selectedSite: Site | undefined,
    setSelectedSite: (s: Site) => void,
    // signedInDepartments: Set<Department>,
    departmentsLoading: boolean,
    departments: Department[],
    sites: Site[],
    providers: Provider[],
    reasons: Reason[],
    refreshAsync: () => void,
    userInfo?: UserInfo,
    setSignedInDepartments: (d: number[]) => void,
    setUserInfoAsync: (u: Partial<UserInfo>) => void,
    // getSignedInDepartmentsSet: () => Set<Department>,
    // getSignedInDepartments: () => Department[],
    pages: Page[],
    pagesLoading: boolean,
    signInUserAsync: () => void,
    signOutUserAsync: () => void,
    searchPages: (filter: PageFilter) => void,
    addQuickPageAsync: (quickPage: QuickPage) => void,
    removeQuickPageAsync: (quickPageId: number) => void,
}

const initial: AppContext = {
    selectedSite: undefined,
    setSelectedSite: (_) => { },
    // signedInDepartments: new Set(),
    setSignedInDepartments: (_) => { },
    departmentsLoading: true,
    departments: [],
    sites: [],
    providers: [],
    reasons: [],
    refreshAsync: () => { },
    pages: [],
    pagesLoading: false,
    searchPages: () => { },
    // getSignedInDepartmentsSet: () => new Set(),
    // getSignedInDepartments: () => [],
    signInUserAsync: () => { },
    signOutUserAsync: () => { },
    setUserInfoAsync: (_) => { },
    addQuickPageAsync: (_) => { },
    removeQuickPageAsync: (_) => { },
    userInfo: undefined,
}

type FormDataRequest = {
    departments: Department[],
    sites: Site[],
    providers: Provider[],
    reasons: Reason[],
}

export type PageFilter = {
    departmentId: number,
    status?: string,
} | {
    myPagesRole: 'creator' | 'responder',
}

export const AppContext = React.createContext<AppContext>(initial);

export const AppContextProvider: FC<{
    children: ReactNode,
}> = ({
    children,
}) => {

        const isMobile = useIsMobile();

        const [connection, setConnection] = useState<HubConnection | null>(null);
        const [selectedSite, setSelectedSite] = useState<Site | undefined>(undefined);
        // const [signedInDepartments, setSignedInDepartments] = useState<Set<Department>>(new Set());
        const [departmentsLoading, setDepartmentsLoading] = useState<boolean>(true);

        const [departments, setDepartments] = useState<Department[]>([]);
        const [sites, setSites] = useState<Site[]>([]);
        const [providers, setProviders] = useState<Provider[]>([]);
        const [reasons, setReasons] = useState<Reason[]>([]);
        const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
        const [pages, setPages] = useState<Page[]>([]);
        const [pagesLoading, setPagesLoading] = useState<boolean>(false);
        const [pagesFilter, setPageFilter] = useState<PageFilter | undefined>(undefined);
        const [firebaseToken, setFirebaseToken] = useState<string | undefined>(undefined);

        const knowledge = useRef<number | null>(null);

        useEffect(() => {
            getUserDataAsync();
            Api.connect().then(connection => setConnection(connection));
        }, []);

        const [initialLogIn, setInitialLogIn] = useState(false);

        useEffect(() => {
            if (!!userInfo && !initialLogIn) {
                setInitialLogIn(true);
                if (isMobile) signInUserAsync();
            }
        }, [userInfo, initialLogIn]);

        useEffect(() => {
            if (!initialLogIn) return;

            if (isMobile) {
                signInUserAsync();
            } else {
                signOutUserAsync();
            }
        }, [isMobile]);

        useEffect(() => {
            if (connection) {
                connection.start()
                    .then(result => {
                        connection.on('SyncAppData', message => {
                            handlePageUpdateRef.current?.(message)
                        });
                        connection.on('SyncUserSite', message => {
                            handleSiteUpdateRef.current?.(message)
                        });
                    })
                    .catch(e => console.error('Connection failed: ', e));

                connection.onreconnected(() => {
                    console.log('Websockets reconnected. Refreshing pages and user data.');
                    getPages();
                    getUserDataAsync();
                })
            }
        }, [connection]);

        const handlePageUpdate = (pageUpdate: SyncAppData) => {
            console.log('Received a page update: ', pageUpdate, 'using filter: ', pagesFilter);
            //Validate knowledge
            if (knowledge.current === null || pageUpdate.knowledge === knowledge.current + 1) {
                knowledge.current = pageUpdate.knowledge;
            } else {
                //Missed updates, refresh (but hidden)
                console.warn('Missed messages, refreshing pages');
                getPages();
                knowledge.current = pageUpdate.knowledge;
                return;
            }

            let changedPage: Page = JSON.parse(pageUpdate.changedEntity);

            const updatedPages = [...pages ?? []];

            // Handle updates
            if (pageUpdate.action === 'add') {
                if (filterPage(changedPage)) {
                    setPages(sortPages([
                        // Filter out any old version of this page.
                        ...updatedPages.filter(p => p.id !== changedPage.id),
                        changedPage
                    ], userInfo?.id));
                }
            } else if (
                pageUpdate.action === 'change' &&
                changedPage.status !== 'DELETED' &&
                changedPage.status !== 'COMPLETED' &&
                !!pagesFilter &&
                (
                    ( // Requestor looking at a department
                        'departmentId' in pagesFilter
                    ) || ( // Requestor looking at my pages
                        pagesFilter?.myPagesRole === 'creator' &&
                        changedPage.creator?.id === userInfo?.id
                    ) || ( // Responder looking at my pages
                        pagesFilter?.myPagesRole === 'responder' && (
                            changedPage.responder === null ||
                            changedPage.responder?.id === userInfo?.id
                        )
                    )
                )
            ) {
                let index = updatedPages.findIndex(p => p.id === changedPage.id);

                console.log('Got page update and found that page at index', index);

                if (index >= 0) {
                    updatedPages[index] = {
                        ...updatedPages[index],
                        ...changedPage,
                    };

                    setPages(sortPages([...updatedPages], userInfo?.id));
                }
            } else if (pageUpdate.action === 'remove') {
                setPages([...updatedPages.filter(p => p.id.toString() !== pageUpdate.changedEntity.toString())]);
            } else if (changedPage.status === 'DELETED' || changedPage.status === 'COMPLETED' || changedPage.responder?.id !== userInfo?.id) {
                setPages([...updatedPages.filter(p => p.id !== changedPage.id)]);
            } else {
                console.error('Invalid page update', changedPage);
            }
        };

        const handleSiteUpdate = (siteId: number) => {
            console.log('Received Site Update', siteId);
            if (siteId !== selectedSite?.id) {
                console.log('Site has changed on another device. Updating on this one to site ', siteId, 'from', selectedSite?.id);
                refreshAsync(siteId, true);
            }
        }

        const handlePageUpdateRef = useRef<(pageUpdate: SyncAppData) => void>();
        const handleSiteUpdateRef = useRef<(siteId: number) => void>();

        handlePageUpdateRef.current = handlePageUpdate;
        handleSiteUpdateRef.current = handleSiteUpdate;


        async function getUserDataAsync() {
            let jsonRes = await Api.get<UserInfo>('/user/info')
                .catch(e => {
                    console.error('Error getting user data: ', e);
                    // alert('Error getting data from the API');
                });

            if (!jsonRes) {
                console.error('No data returned');
                // alert('Error getting data from the API');
                return;
            }

            setUserInfo(jsonRes);

            await refreshAsync(jsonRes.activeSite, true);
        }

        async function refreshAsync(siteId: number | undefined = undefined, forceSiteChange = false) {

            setDepartmentsLoading(true);


            siteId = siteId ??
                selectedSite?.id ??
                userInfo?.activeSite ??
                userInfo?.defaultSiteId ??
                1;

            console.log('Getting initial data from the server. User Info: ', userInfo, siteId);

            let jsonRes = await Api.get<FormDataRequest>('/form-data', {
                siteId: siteId.toString()
            })
                .catch(e => {
                    console.error('Error getting form data: ', e);
                    // alert('Error getting data from the API');
                });

            if (!jsonRes) {
                console.error('No data returned');
                // alert('Error getting data from the API');
                return;
            }

            if (!selectedSite || forceSiteChange) {

                console.log('Updating the site to', siteId);

                //If the user has a default site use that. Otherwise use the first site
                let site = jsonRes.sites.find(s => s.id === siteId) ?? jsonRes.sites[0];

                changeTheme(site);
                setSelectedSite(site);
            }

            setDepartments(jsonRes.departments);
            setSites(jsonRes.sites);
            setProviders(jsonRes.providers);
            setReasons(jsonRes.reasons);
            setDepartmentsLoading(false);
        }

        function searchPages(filter: PageFilter) {
            setPagesLoading(true);
            setPages([]);
            setPageFilter(filter);
        }


        /**
         * Returns `true` if the page passes the current filter
         * @param {Page} page
         * @returns {boolean}
         */
        function filterPage(page: Page) {

            if (!pagesFilter) return false;

            // Only if they are in the same site
            if (page.site.id !== selectedSite?.id) return false;

            if ('departmentId' in pagesFilter) {
                if (pagesFilter.departmentId !== page.department.id) return false;

            } else if (pagesFilter.myPagesRole === 'creator' && page.creator.id !== userInfo?.id) {
                return false;
            } else if (pagesFilter.myPagesRole === 'responder') {
                if (!userInfo?.activeDepartments.find(d => d === page.department.id))
                    return false;
                if (!!page.provider?.id && page.provider.id !== userInfo?.id)
                    return false;
            }

            return true;
        }

        async function setFirebaseTokenAsync(token: string) {
            Api.put('/user/info', {
                FCMToken: token,
            })
                .catch(e => {
                    console.error('Error updated the firebase token: ', e);
                });
        }

        async function signInUserAsync() {

            console.log('Signing in', userInfo);
            let token = firebaseToken;

            if (!token) {
                token = await getTokenAsync();

                //Update if successful
                if (token) setFirebaseToken(token);
            }

            if (token) await setFirebaseTokenAsync(token);

            // If the user has no active departments, then do /user/sign-in to sign into the default departments
            if ((userInfo?.activeDepartments ?? []).length === 0) {
                Api.put('/user/sign-in', {})
                    .then(() => {
                        const userInfoUpdate: UserInfo = { ...userInfo } as UserInfo;
                        userInfoUpdate.activeDepartments = [...(userInfo?.defaultDepartments ?? [])]
                        setUserInfo(userInfoUpdate);
                    })
                    .catch(e => {
                        console.error('Error getting user data: ', e);
                        // alert('Error getting data from the API');
                    });
            }
        }

        async function signOutUserAsync() {
            if (!userInfo) return;

            Api.put('/user/sign-out', {})
                .catch(e => {
                    console.error('Error getting user data: ', e);
                    // alert('Error getting data from the API');
                });

            const userInfoUpdate = { ...userInfo };
            userInfoUpdate.activeDepartments = [];
            setUserInfo(userInfoUpdate);
        }

        async function getPages() {
            if (!pagesFilter) return;

            // Should use my pages or normal pages endpoint
            const url = ('departmentId' in pagesFilter) ? 'pages' : 'user/pages';
            const data: { siteId: string, departmentId: string, } | { r: string } = ('departmentId' in pagesFilter) ? {
                siteId: selectedSite?.id.toString() ?? 'null',
                departmentId: pagesFilter.departmentId.toString(),
            } : {
                r: pagesFilter.myPagesRole,
                siteId: selectedSite?.id.toString() ?? 'null',
            }

            Api.get(url, data)
                .then(json => {
                    setPagesLoading(false);
                    if (json && Array.isArray(json)) setPages(sortPages(json, userInfo?.id));
                })
                .catch(err => {
                    setPagesLoading(false);
                    console.error('Error getting pages', err);
                })
        }

        useEffect(() => {
            if (!!selectedSite)
                changeSiteAsync(selectedSite!);
        }, [selectedSite]);

        useEffect(() => {
            getPages();
        }, [pagesFilter, selectedSite]);

        const changeSiteAsync = async (site: Site) => {
            changeTheme(site);

            // Update in the database if needed
            if (userInfo?.activeSite !== site.id)
                await setUserInfoAsync({
                    activeSite: site.id,
                    // defaultSiteId: site.id,
                } as UserInfo);

            refreshAsync();
        }

        const setSignedInDepartments = (departments: number[]) => {

            if (!userInfo) return;

            userInfo.activeDepartments = [...departments];

            setUserInfo({ ...userInfo });

            Api.put('user/info', {
                activeDepartments: departments,
            })
                .then(_ => {
                    //If on my pages as a responder...
                    if (pagesFilter && 'myPagesRole' in pagesFilter && pagesFilter.myPagesRole === 'responder') {
                        //If it needs to visibly update
                        //setPagesLoading(true);
                        getPages();
                    }
                });
        }

        const setUserInfoAsync = async (info: Partial<UserInfo>) => {
            if (!userInfo) return;

            setUserInfo({ ...userInfo, ...info });

            await Api.put('user/info', info);
        }

        const addQuickPageAsync = async (quickPage: QuickPage) => {
            if (!userInfo) return;

            const res = await Api.post<QuickPage>('quick-pages', quickPage);

            userInfo.quickPages = [...userInfo.quickPages, res];

            setUserInfo({ ...userInfo });
        }

        const removeQuickPageAsync = async (quickPageId: number) => {
            if (!userInfo) return;

            await Api.delete(pathJoin('quick-pages', quickPageId.toString()), {});

            userInfo.quickPages = [...userInfo.quickPages.filter(qp => qp.id !== quickPageId)];

            setUserInfo({ ...userInfo });
        }

        return <AppContext.Provider value={{
            selectedSite,
            setSelectedSite,
            setSignedInDepartments,
            // getSignedInDepartments,
            // getSignedInDepartmentsSet,
            departmentsLoading,
            departments,
            sites,
            providers,
            reasons,
            refreshAsync,
            pages,
            pagesLoading,
            searchPages,
            userInfo,
            signInUserAsync,
            signOutUserAsync,
            setUserInfoAsync,
            addQuickPageAsync,
            removeQuickPageAsync,
        }}>{children}</AppContext.Provider>
    }

export const useAppData = () => {
    return useContext(AppContext);
}