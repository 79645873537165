import React from 'react';
import ReactDOM from 'react-dom/client';
import * as msal from "@azure/msal-browser";
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import './css/theme.css';
import './css/fonts.css';
import './css/index.css';
import 'react-toggle/style.css';


import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(window.location.origin + '/serviceWorker.js')
    .then(function(registration) {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }).catch(function(err) {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
    });
}

const msalInstance = new msal.PublicClientApplication(msalConfig);

ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
).render(
    // This really messes up data fetching...
    // <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Router>
                <App />
            </Router>
        </MsalProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
