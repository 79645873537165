import { loginRequest, msalConfig } from './../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';

const publicClientApplication = new PublicClientApplication(msalConfig);
var accessToken: string;
var tokenExpiration: Date;

const makeAccessTokenRequestAsync = async () => {
    const account = publicClientApplication.getAllAccounts()[0];

    const accessTokenRequest = {
        scopes: loginRequest.scopes,
        account: account,
    };

    await publicClientApplication.acquireTokenSilent(accessTokenRequest)
    .then((accessTokenResponse) => {
        accessToken = accessTokenResponse.accessToken;
        tokenExpiration =
            accessTokenResponse.expiresOn ??
            new Date(new Date().getTime() + 60 * 10);
    }).catch((error) => {
        // Notify.error('Error Authenticating', 'An error occurred while trying authorize you. If this issue persist, try opening this page in an incognito or private tab.');
        console.error(error);
    });
}

export const getAccessTokenExpiration = () => tokenExpiration;

/**
 * Gets the latest access token.
 */
export const getAccessTokenAsync = async (force = false): Promise<string> => {
    if (!accessToken || force || tokenExpiration < new Date()) await makeAccessTokenRequestAsync();
    return accessToken;
}


// /**
//  * Gets the current user. Returns `null` if there is no user signed in.
//  * @returns {import("@azure/msal-browser").AccountInfo|null} Returns the current user
//  */
// export const useUserDetails = () => {
//     return useContext(UserDetailsContext)?.account;
// }


// /**
//  * Gets the current user's permissions. Returns `null` if there is no user signed in.
//  * @returns {string[]|null} Returns the current user's permissions
//  */
// export const usePermissions = () => {
//     return useContext(UserDetailsContext)?.account?.idTokenClaims?.roles ?? null;
// }


// /**
//  * Checks a permission or an array of permissions for the current user.
//  * @param {string[]|string} permissions A permission or arrays of permissions to check. Example: `'Hospital.Write'`, `['Branches.Read', 'Zipcodes.Read']`
//  * @returns {boolean?} Returns `true` if the user has all permissions 
//  */
// export const useHasPermission = (permissions) => {
//     if (typeof permissions === 'string') permissions = [permissions];

//     const details = useUserDetails();

//     return permissions
//         .map(p => details?.idTokenClaims?.roles?.includes(p))
//         .reduce((a, b) => (a === undefined) ? a : a && b, true)
//         ?? null;
// }


// /**
//  * Gives a list of all the user's current permissions
//  * @returns {Object} Returns map of resources and their permissions
//  */
// export const usePermissionMap = () => {
//     const details = useUserDetails();

//     return (details?.idTokenClaims?.roles ?? []).reduce((prev, p) => {
//         let [resource, action] = p.split('.');

//         if (!prev[resource]) {
//             prev[resource] = [action]
//         } else {
//             prev[resource].push(action);
//         }

//         return prev;
//     }, {});
// }


// /**
//  * Mock permissions with this. Will effect the UI, but not the backend.
//  */

// export const usePermissionsMock = () => {
//     const userDetails = useContext(UserDetailsContext);
//     const permissions = usePermissions();

//     const add = permission => {
//         if (!permission) return;

//         let account = structuredClone(userDetails.account);

//         if (account?.idTokenClaims?.roles)
//             account.idTokenClaims.roles = [...account.idTokenClaims.roles, permission];

//         userDetails.setAccount(account);
//     };

//     const remove = permission => {
//         if (!permission) return;

//         let account = structuredClone(userDetails.account);

//         if (account?.idTokenClaims?.roles)
//             account.idTokenClaims.roles = account?.idTokenClaims.roles.filter(p => p !== permission);

//         userDetails.setAccount(account);
//     }

//     const toggle = permission => {
//         if (permissions?.includes(permission)) {
//             remove(permission);
//         } else {
//             add(permission);
//         }
//     }

//     const reset = () => userDetails.resetAccount();

//     return {
//         add: add,
//         remove: remove,
//         toggle: toggle,
//         reset: reset,
//     }
// }