import { FC } from 'react';
import { css } from '../../../utils/css';
import styles from './Dropdown.module.css';

export enum DropdownAlignment {
    Right,
    Left,
    Center
}

export type DropdownParams = {
    expanded: boolean,
    onChange: (e: boolean) => void,
    children: React.ReactNode,
    dropContents: React.ReactNode,
    alignment?: DropdownAlignment,
    className?: string,
    width?: string,
    [key: string]: any,
}

export const Dropdown: FC<DropdownParams> = ({
    expanded,
    onChange,
    children,
    dropContents,
    alignment = DropdownAlignment.Left,
    className,
    width,
    ...props
}) => {

    function handleBlur(e: any) {
        if (!e.currentTarget?.contains(e.relatedTarget)) {
            onChange(false);
        }
    }

    return <div
        {...props}
        className={css(
            styles.Dropdown,
            className,
            alignment === DropdownAlignment.Right && styles.Right,
            alignment === DropdownAlignment.Left && styles.Left,
            alignment === DropdownAlignment.Center && styles.Center,
        )}
        tabIndex={-1}
        onBlur={handleBlur}
    >
        {children}
        {/* <div className={styles.DropdownMenuWrapper}> */}
            <div
                className={css(styles.DropdownMenu, expanded && styles.Expanded)}
                style={{width: width}}
            >
                {dropContents}
            </div>
        {/* </div> */}
    </div>
}