import { useEffect } from 'react';
import { useParams } from 'react-router';
import ReactTimeago from 'react-timeago';
import { ScrollTable } from '../../../shared/table/ScrollTable';
import { ReactComponent as EngageSvg } from './../../../../assets/icons/engage.svg';
import { useAppData } from './../../../../contexts/AppData';
import { css } from './../../../../utils/css';
import { LoadText } from './../../../shared/loading/LoadText';
import styles from './CurrentPages.module.css';

const COLUMNS = {
    location: {
        label: 'Location',
        size: 'minmax(0, 1.75fr)',
    },
    pageSent: 'Page Sent',
    reason: 'Reason',
    responder: 'Responder',
    eta: 'ETA',
    engaged: {
        label: 'Engaged',
        size: '100px',
    },
}

export const CurrentPages = () => {

    const { department } = useParams();

    const {
        departments,
        pages,
        pagesLoading,
        searchPages,
    } = useAppData();

    useEffect(() => {
        searchPages({
            departmentId: department ? parseInt(department) : -1,
            status: undefined,
        });
    }, [department, departments]);

    const getLoadingRow = () => Object.keys(COLUMNS).reduce((a: any, key) => {a[key] = <LoadText height={'22px'} />; return a}, {id: Math.random()})
    const loadingDataSet = () => [...new Array(10)].map(_ => getLoadingRow())

    const processRows = () => pages
    .map(r => ({
        id: r.id.toString(),
        location: r.location,
        pageSent: <ReactTimeago date={r.createdAt.endsWith('Z') ? r.createdAt : r.createdAt + 'Z'} />,
        reason: r.reason,
        responder: r.responder?.name ?? 'None',
        eta: r.status === 'ACCEPTED' ? <ReactTimeago date={r.eta?.endsWith('Z') ?? true ? r.eta : r.eta + 'Z'} /> : '',
        engaged: r.status === 'ENGAGED' ?
            <div className={css(styles.Engage, styles.Engaged)}><EngageSvg /></div>
            : <div className={css(styles.Engage)}><EngageSvg /></div>,
    }));

    return <ScrollTable
        message={!pagesLoading && pages.length === 0 ? 'No Results' : undefined}
        columns={COLUMNS}
        data={pagesLoading ? loadingDataSet() : processRows()}
    />
}