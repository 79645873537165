import { useRef, useEffect, useState } from 'react';

export const useIsMounted = () => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false; }
    }, []);

    return isMounted;
}

export const useIsMatchedMedia = (media: string) => {
    const [mediaQueryList, _] = useState(matchMedia(media));
    const [matched, setMatched] = useState(mediaQueryList.matches);

    const handleChange = (e: MediaQueryListEvent) => {
        setMatched(e.matches);
    }

    useEffect(() => {
        mediaQueryList.addEventListener('change', handleChange);

        return () => {
            mediaQueryList.removeEventListener('change', handleChange);
        }
    }, [mediaQueryList]);

    return matched;
}

export const useIsMobile = () => {
    const isMobile = useIsMatchedMedia('(max-width: 900px)');

    // Hard code mobile settings for certain hosts
    if (window.location.host === 'requestor.slandchc.com') return false;
    if (window.location.host === 'responder.slandchc.com') return true;
    return isMobile;
}