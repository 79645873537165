import { useAppData } from './../../../contexts/AppData';
import { FC, useEffect, useState } from 'react';
import { css } from '../../../utils/css';
import { useIsMobile } from '../../../utils/hooks';
import { MobilePageCard } from '../../shared/cards/MobilePageCard';
import { Header } from '../../shared/header/Header';
import { ReactComponent as EngageSvg } from './../../../assets/icons/engage.svg';
import { ReactComponent as HomeSvg } from './../../../assets/icons/home.svg';
import ReactTimeago from 'react-timeago';
import { ScrollTable } from '../../shared/table/ScrollTable';
import { LoadText } from '../../shared/loading/LoadText';
import { Api, pathJoin } from './../../../utils/api';
import { Loader } from '../../shared/loading/Loader';


import styles from './MyPages.module.css';

const COLUMNS = {
    location: {
        label: 'Location',
        size: 'minmax(0, 1.75fr)',
    },
    department: {
        label: 'Department',
        size: 'minmax(0, 1fr)',
    },
    pageSent: 'Page Sent',
    reason: 'Reason',
    responder: 'Responder',
    eta: 'ETA',
    engaged: {
        label: 'Engaged',
        size: '100px',
    },
    cancel: {
        cellClassName: styles.CancelBtnCell,
        label: '',
        size: '125px',
    }
}

export const MyPages: FC = () => {

    const isMobile = useIsMobile();

    const {
        pages,
        pagesLoading,
        searchPages,
    } = useAppData();

    useEffect(() => {
        searchPages({
            myPagesRole: isMobile ? 'responder' : 'creator',
        });
    }, [isMobile]);

    const [cancelingPages, setCancelingPages] = useState<number[]>([]);


    const cancelPage = async (id: number) => {
        setCancelingPages(prev => [...prev, id]);

        await Api.delete(pathJoin('pages', id.toString()), {});

        setCancelingPages(prev => [...prev.filter(x => x !== id)]);
    }

    const getLoadingRow = () => Object.keys(COLUMNS).reduce((a: any, key) => {a[key] = <LoadText height={isMobile ? '16px' : '22px'} />; return a}, {id: Math.random()})
    const loadingDataSet = () => [...new Array(10)].map(_ => getLoadingRow())

    const processRows = () => pages
    .map(r => ({
        id: r.id.toString(),
        department: r.department.name,
        location: r.location,
        pageSent: <ReactTimeago date={r.createdAt.endsWith('Z') ? r.createdAt : r.createdAt + 'Z'} />,
        reason: r.reason,
        responder: r.responder?.name ?? 'None',
        eta: r.status === 'ACCEPTED' ? <ReactTimeago date={r.eta?.endsWith('Z') ?? true ? r.eta : r.eta + 'Z'} /> : '',
        engaged: r.status === 'ENGAGED' ?
            <div className={css(styles.Engage, styles.Engaged)}><EngageSvg /></div>
            : <div className={css(styles.Engage)}><EngageSvg /></div>,
        cancel: <button
            className={css(styles.CancelBtn)}
            disabled={r.status === 'ENGAGED' || cancelingPages.includes(r.id)}
            onClick={() => cancelPage(r.id)}
        >
            {cancelingPages.includes(r.id) ? <Loader color='currentColor' size={4} height={12} /> : 'Cancel'}
        </button>
    }));

    return <section className={css(styles.MyPages, isMobile && styles.Mobile)}>
        {!isMobile && <Header icon={<HomeSvg />}>
            <b>My Pages</b>
        </Header>}
        {isMobile ?
        <>
            {pagesLoading ? <div className={styles.NoPagesMessage}><h1>No Pages</h1></div> : pages.map(d => <MobilePageCard key={d?.id ?? Math.random()} loading={pagesLoading} {...d} />)}
            {(!pagesLoading && pages.length === 0) && <div className={styles.NoPagesMessage}>
                <h1>No Pages</h1>
                <p>There aren't any pages in any of the departments you are assigned to.</p>
            </div>}
        </>
            :
            <ScrollTable
                className={css(styles.Table)}
                message={!pagesLoading && pages.length === 0 ? 'No Results' : undefined}
                columns={COLUMNS}
                data={pagesLoading ? loadingDataSet() : processRows()}
            />
        }
    </section>
}