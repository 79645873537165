import { FC, useState } from 'react';
import { Header } from '../../shared/header/Header';
import { DepartmentSelector, DepartmentSelectorDirection } from '../../shared/inputs/DepartmentSelector';
import { Table } from '../../shared/table/Table';
import { ReactComponent as QuickPageIcon } from './../../../assets/icons/quick-page.svg';
import { useAppData } from './../../../contexts/AppData';
import styles from './QuickPages.module.css';
import { Api } from '../../../utils/api';


const COLUMNS = {
    location: {
        label: 'Location',
        size: 'minmax(0, 1fr)',
    },
    departments: {
        label: 'Departments',
        size: 'minmax(0, 3fr)',
    },
}

export const QuickPages: FC = () => {

    const {
        userInfo,
        selectedSite,
        departments
    } = useAppData();

    const [posting, setPosting] = useState<{departmentId: number, quickPageId: number}[]>([]);

    const sendPage = (d: number[], quickPageId: number, location: string) => {
        if (!selectedSite) return;

        let departmentId = d[0];

        setPosting([...posting, {departmentId: departmentId, quickPageId: quickPageId}]);

        Api.post('pages', {
            siteId: selectedSite?.id ?? 'null',
            departmentId: d[0],
            location: location,
            provider: null,
            reason: 'Other: Quick Page',
        })
        .then(res => {
            setPosting(posting => posting.filter(p => p.departmentId !== d[0] || p.quickPageId !== quickPageId));
        })
        .catch(err => {
            setPosting(posting => posting.filter(p => p.departmentId !== d[0] || p.quickPageId !== quickPageId));
            // setError('There was an issue sending the page');
            console.error('Error posting page', err);
        })
    }

    const getQuickPages = () => userInfo?.quickPages.map(qp => ({
        id: qp.id,
        location: <div className={styles.DepartmentsTextWrapper}>
            <div className={styles.DepartmentsText}>
                {qp.location}
            </div>
        </div>,
        departments: <div 
            className={styles.Departments}>
            <DepartmentSelector
                loadingIds={posting.filter(p => p.quickPageId === qp.id).map(p => p.departmentId)}
                direction={DepartmentSelectorDirection.Row}
                value={departments.map(d => d.id)}
                onChange={(d) => sendPage(d, qp.id, qp.location)}
            />
        </div>,
    })) ?? [];

    return <section className={styles.QuickPages}>
        <Header icon={<QuickPageIcon />}>
            <b>Quick Pages</b>
        </Header>
        <Table
            message={userInfo?.quickPages?.length === 0 ? 'No Quick Pages. Add some in settings then come back here.' : null}
            columns={COLUMNS}
            data={getQuickPages()}
        />
    </section>
}