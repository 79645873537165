import { Page } from '../models/page'

export const sortPages = (pages: Page[], selfId?: number): Page[] => 
    pages.sort((a, b) => {
        if (a.status === 'ENGAGED' && b.status !== 'ENGAGED') return -1;
        if (b.status === 'ENGAGED' && a.status !== 'ENGAGED') return 1;
        if (a.status === 'ACCEPTED' && b.status !== 'ACCEPTED') return -1;
        if (b.status === 'ACCEPTED' && a.status !== 'ACCEPTED') return 1;

        if (selfId) {
            if (a.responder?.id === selfId && b.responder?.id !== selfId) return -1;
            if (b.responder?.id === selfId && a.responder?.id !== selfId) return 1;
        }
        
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    });