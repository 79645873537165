import { ScaleLoader } from 'react-spinners'

interface LoaderProps {
    size?: number,
    [key: string]: any,
}
export const Loader = ({
    size = 4,
    ...props
}: LoaderProps) => {
    return <ScaleLoader
        width={size}
        height={size * 10}
        margin={size / 2}
        radius={size / 2}
        color='var(--primary-500)'
        {...props}
    />
}