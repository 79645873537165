
import * as msal from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { ReactComponent as LoginSvg } from './assets/icons/login.svg';
import { HeaderNav } from './components/feature/headerNav/HeaderNav';
import { MyPages } from './components/feature/myPages/MyPages';
import { QuickPages } from './components/feature/quickPages/QuickPages';
import { Settings } from './components/feature/settings/Settings';
import { WorkRequests } from './components/feature/workRequests/WorkRequests';
import { DepartmentNav } from './components/shared/department/DepartmentNav';
import { AppContextProvider } from './contexts/AppData';
import { requestPermission } from './firebase';
import { css } from './utils/css';
import { useIsMobile } from './utils/hooks';


import styles from './App.module.css';


export const App = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const isMobile = useIsMobile();

    useEffect(() => {
        if (isMobile && location.pathname !== '/mypages' && location.pathname !== '/') {
            navigate('/mypages');
        }
    }, [isMobile]);

    const masl = useMsal();

    if (isMobile) {
        requestPermission();
    }


    function signOut() {
        masl.instance.logout();
    }

    return (
        <MsalAuthenticationTemplate interactionType={msal.InteractionType.Redirect}>
            <AppContextProvider>
                <div className={css(styles.App, isMobile && 'mobile')}>
                    {!isMobile && <nav>
                        <div className={css(styles.Lighter, styles.Login)}>
                            <LoginSvg onClick={signOut} />
                        </div>
                        <DepartmentNav />
                    </nav>}
                    
                    <div className={styles.Content}>
                        <HeaderNav />
                        <main className={styles.ContentMain}>
                            <Routes>
                                <Route path='/departments/:department' element={<WorkRequests />} />
                                <Route path='/mypages' element={<MyPages />} />
                                <Route path='/quickpages' element={<QuickPages />} />
                                <Route path='/settings' element={<Settings />} />
                                <Route
                                    path='/'
                                    element={<Navigate to='/mypages' replace />}
                                />
                            </Routes>
                        </main>
                    </div>
                </div>
            </AppContextProvider>
        </MsalAuthenticationTemplate>
    );
}