import { FC, useEffect, useState } from 'react';
import { css } from '../../../utils/css';
import { ReactComponent as RespondIcon } from './../../../assets/icons/respond-icon.svg';
import { Icon } from '../icons/Icon';
import { Api } from './../../../utils/api';
import { Loader } from '../loading/Loader';
import { useAppData } from './../../../contexts/AppData';
import ReactTimeago from 'react-timeago';
import { Page, PageStatus } from './../../../models/page';

import style from './MobilePageCard.module.css';
import { LoadText } from '../loading/LoadText';
import { Dropdown, DropdownAlignment } from '../dropdown/Dropdown';


const STATUS_TEXT: {[key: string]: string} = {
    'default': 'Respond',
    'NEW': 'Respond',
    'ACCEPTED': 'Responding',
    'ENGAGED': 'Engaged',
}

export const MobilePageCard: FC<Page & {loading: boolean}> = ({
    id,
    location,
    createdAt,
    reason,
    provider,
    responder,
    eta,
    department,
    status,
    loading,
}) => {

    const [expanded, setExpanded] = useState(false);
    const [etaExpanded, setEtaExpanded] = useState(false);
    const [inputEta, setInputEta] = useState(0);

    const {
        departments,
        userInfo,
    } = useAppData();

    const [updatingPageEta, setUpdatingPageEta] = useState(false);
    const [changingPageStatus, setChangingPageStatus] = useState(false);

    const respondToPage = async () => {
        setUpdatingPageEta(true);

        await Api.put('pages/' + id, {
            status: 'ACCEPTED',
            eta: inputEta === 0 ? null : inputEta,
        });

        setEtaExpanded(false);

        setUpdatingPageEta(false);
    }

    const updateStatus = async (status: PageStatus) => {
        setChangingPageStatus(true);

        await Api.put('pages/' + id, {
            status: status,
        });

        setChangingPageStatus(false);
    }
    
    useEffect(() => {
        // Make sure the card isn't expanded when the status is ENGAGED
        if (status === 'ENGAGED' && expanded === true)
            setExpanded(false);
    }, [status]);


    return <div className={css(style.Card, (status === 'ACCEPTED' || status === 'ENGAGED') && style.Active)}>

        <div className={style.CardContents}>


            <div className={style.DepartmentIcon}>
                <Icon src={departments.find(d => d.id === department?.id)?.icon} />
            </div>

            {/* {(status === 'ACCEPTED' || status === 'ENGAGED') && <div className={style.Banner}>{
                    status === 'ACCEPTED' ? 'Responding'
                    : status === 'ENGAGED' ? 'Engaged'
                    : ''
                }</div>} */}
            
            <div className={style.Stat}>
                <div className={style.Label}>{status === 'ACCEPTED' ? 'ETA' : 'Sent'}</div>
                <div>{loading ? <LoadText /> : status === 'ACCEPTED' ?
                    eta ? <ReactTimeago date={eta.endsWith('Z') ? eta : eta + 'Z'} /> : 'None'
                    : <ReactTimeago date={createdAt.endsWith('Z') ? createdAt : createdAt + 'Z'} />
                }</div>
            </div>
            
            <div className={style.Stat}>
                <div className={style.Label}>Reason</div>
                <div>{loading ? <LoadText /> : reason ?? 'None'}</div>
            </div>

            <div className={css(style.Stat, style.Multiline)}>
                <div className={css(style.Label, style.Wide)}>Location</div>
                <div className={css(style.Wide)}>{loading ? <LoadText /> : location ?? 'None'}</div>
            </div>
        </div>

        <div className={css(style.Respond, expanded && style.Expanded)}>
            <div className={style.Label} onClick={() => { if (status !== 'ENGAGED') setExpanded(!expanded); }}>
                <div className={style.Text}>
                    {loading ? <LoadText dark={false} strength={4} /> : STATUS_TEXT[status] ?? STATUS_TEXT['default']}
                </div>
                
                {status !== 'ENGAGED' ?
                    <div className={style.Icons}>
                        <div className={css(style.Icon, style.ExpandIcon)}>
                            <RespondIcon />
                        </div>
                    </div>
                    : <button disabled={changingPageStatus} onClick={() => updateStatus('COMPLETED')}>
                        {changingPageStatus ? <Loader color='currentColor' size={4} height={8} /> : 'Complete'}
                    </button>
                }
            </div>
            <div className={style.ExpandContent}>
                {status === 'NEW' && 
                    <input
                        max={60}
                        title='ETA'
                        type='range'
                        value={inputEta}
                        onChange={e => setInputEta(parseInt(e.target.value))}
                    />
                }
                
                <div className={css(style.Actions, status === 'ACCEPTED' && style.Accepted)}>
                    {status === 'NEW' &&
                        <div className={style.EtaOutput}>
                            <span className={style.EtaLabel}>ETA:</span> {inputEta}
                        </div>
                    }

                    {status === 'NEW' && !!userInfo && provider?.id === userInfo.id &&
                        <button className={style.Secondary} disabled={changingPageStatus} onClick={() => updateStatus('NEW')}>
                            {changingPageStatus ? <Loader color='currentColor' size={4} height={8} /> : 'Reject'}
                        </button>
                    }

                    {status === 'NEW' && 
                        <button disabled={updatingPageEta} onClick={() => respondToPage()}>
                            {updatingPageEta ? <Loader color='currentColor' size={4} height={8} /> : 'Respond'}
                        </button>
                    }

                    {status === 'ACCEPTED' && <Dropdown
                        expanded={etaExpanded}
                        onChange={setEtaExpanded}
                        width='calc(100vw - 85px)'
                        alignment={DropdownAlignment.Left}
                        dropContents={<div className={style.Dropdown}>
                            <input
                                max={60}
                                title='ETA'
                                type='range'
                                value={inputEta}
                                onChange={e => setInputEta(parseInt(e.target.value))}
                            />
                            <div className={style.Actions}>
                                <div className={style.EtaOutput}>
                                    <span className={style.EtaLabel}>ETA:</span> {inputEta}
                                </div>
                                <button className={style.Secondary} disabled={updatingPageEta} onClick={() => respondToPage()}>
                                    {updatingPageEta ? <Loader color='currentColor' size={4} height={8} /> : 'Update'}
                                </button>
                            </div>
                        </div>
                        }
                    >
                        <button className={style.Secondary} disabled={updatingPageEta} onClick={() => setEtaExpanded(true)}>
                            {updatingPageEta ? <Loader color='currentColor' size={4} height={8} /> : 'ETA'}
                        </button>
                    
                    </Dropdown>}



                    {status === 'ACCEPTED' && <>
                        <button className={style.Secondary} disabled={changingPageStatus} onClick={() => updateStatus('NEW')}>
                            {changingPageStatus ? <Loader color='currentColor' size={4} height={8} /> : 'Cancel'}
                        </button>
                        <button disabled={changingPageStatus} onClick={() => updateStatus('ENGAGED')}>
                            {changingPageStatus ? <Loader color='currentColor' size={4} height={8} /> : 'Engage'}
                        </button>
                    </>
                    }

                    
                </div>

               
            </div>
        </div>
    </div>
}