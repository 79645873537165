
import { FC } from 'react';
import { Department } from '../../../models/department';
import { css } from '../../../utils/css';
import { Icon } from '../icons/Icon';
import styles from './DepartmentButton.module.css';
import { Loader } from '../loading/Loader';


interface DepartmentsButtonParams {
    department: Department,
    active?: boolean,
    loading?: boolean,
    className?: string,
    [key: string]: any,
};

export const DepartmentsButton: FC<DepartmentsButtonParams> = ({
    department,
    active = false,
    loading = false,
    className = undefined,
    ...props
}: DepartmentsButtonParams) => {
    return <button
        disabled={loading}
        className={css(className, styles.DepartmentButton, active && styles.Active)}
        title={department.name}
        {...props}
    >
        {
            loading ? <div><Loader size={3} /></div>
            : <Icon src={department.icon} />
        }
    </button>
}