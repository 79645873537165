import { FC, useState } from 'react';
import { ReactComponent as SiteSvg } from './../../../assets/icons/location.svg';
import { css } from '../../../utils/css';
import { Dropdown } from '../dropdown/Dropdown';

import styles from './SiteSelector.module.css';
import { Site } from '../../../models/site';
import { useAppData } from '../../../contexts/AppData';
import { Icon } from '../icons/Icon';

export type SiteSelectorParams = {
    value?: Site,
    onChange: (l: Site) => void,
}

export const SiteSelector: FC<SiteSelectorParams> = ({
    value,
    onChange,
}) => {
    const [expanded, setExpanded] = useState(false);

    const {
        sites
     } = useAppData();

    function handleClick(l: Site) {
        if (l !== value)
            setExpanded(false);
        onChange(l);
    }

    return <Dropdown
        expanded={expanded}
        onChange={setExpanded}
        width='200px'
        dropContents={sites.map(site => <button
                key={site.id}
                title={site.name}
                className={css(styles.Option, value?.id === site.id && styles.Active)}
                onClick={() => handleClick(site)}
            >
                <Icon src={site.icon} mask={false} />
            </button>
        )}
    
    >
        <button className={styles.Icon} onClick={() => setExpanded(!expanded)} title='Sites'>
            <SiteSvg />
        </button>
       
    </Dropdown>
}