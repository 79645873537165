import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppData } from '../../../contexts/AppData';
import { css } from '../../../utils/css';
import { Header } from '../../shared/header/Header';
import { Icon } from '../../shared/icons/Icon';
import { CurrentPages } from './currentPages/CurrentPages';
import { NewPage } from './newPage/NewPage';
import { ResponderStaff } from './responderStaff/ResponderStaff';
import styles from './WorkRequests.module.css';
import { UserInfo } from './../../../models/userInfo';
import { Api } from './../../../utils/api';


export const WorkRequests: FC = () => {

    const { department } = useParams();
    const [currentPage, setCurrentPage] = useState('requests');

    const [loadingResponders, setLoadingResponders] = useState(false);
    const [responders, setResponders] = useState<UserInfo[]>([]);

    const {
        departments,
        selectedSite,
    } = useAppData();

    useEffect(() => {
        setLoadingResponders(true);
        Api.get<UserInfo[]>('users/active', {
            department: department ?? '',
            site: selectedSite?.id.toString()!,
        }).then(json => {
            setLoadingResponders(false);
            setResponders(json);
        }).catch(ex => {
            setLoadingResponders(false);
        })
    }, [department, selectedSite]);

    const departmentInfo = () => departments.find(d => d.id.toString() === department);


    return <section className={styles.WorkRequests}>
        <section className={styles.Tabs}>
            <button
                onClick={() => setCurrentPage('requests')}
                className={css(styles.Tab, currentPage === 'requests' && styles.Active)}
            >
                Requests
            </button>
            <button
                onClick={() => setCurrentPage('newPage')}
                className={css(styles.Tab, currentPage === 'newPage' && styles.Active)}
            >
                New Page
            </button>
            <button
                onClick={() => setCurrentPage('responderStaff')}
                className={css(styles.Tab, currentPage === 'responderStaff' && styles.Active)}
            >
                Responder Staff
            </button>
        </section>
        <main className={css(styles.TabContent, currentPage === 'requests' && styles.Active)}>
            <Header
                className={styles.IconHeader}
                icon={<Icon src={departmentInfo()?.icon} />}
            >
                <b>{departmentInfo()?.name ?? '?'}</b>: Current Pages
            </Header>
            <CurrentPages />
        </main>
        <section className={css(styles.TabContent, currentPage === 'newPage' && styles.Active)}>
            <NewPage department={departmentInfo()} responders={responders} />
        </section>
        <section className={css(styles.TabContent, currentPage === 'responderStaff' && styles.Active)}>
            <ResponderStaff responders={responders} loading={loadingResponders} />
        </section>
    </section>
}

