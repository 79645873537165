import React from 'react';

import styles from './LoadText.module.css';

interface LoadTextProps {
    dark?: Boolean,
    max?: number,
    min?: number,
    width?: string|null,
    height?: string|null,
    strength?: number,
}

export const LoadText = ({
    dark = true,
    max = 85,
    min = 20,
    strength = 1,
    height = '0.8em',
    width = null
}: LoadTextProps) => {
    return <div className={styles.LoadText + (!dark ? ' ' + styles.Light : '')} style={{
        '--width': width ? width : Math.round(Math.random() * (max - min) + min) + '%',
        '--strength': strength,
        '--height': height,
    } as any}></div>
}