import { FC, useEffect, useState } from 'react';
import { Header } from '../../../shared/header/Header';
import { ScrollTable } from '../../../shared/table/ScrollTable';
import { Table } from '../../../shared/table/Table';
import styles from './ResponderStaff.module.css';
import { Api } from './../../../../utils/api';
import { UserInfo } from './../../../../models/userInfo';
import { LoadText } from './../../../../components/shared/loading/LoadText';

const COLUMNS = {
    name: '',
    role: ''
}

export const ResponderStaff: FC<{
    responders: UserInfo[],
    loading: boolean
}> = ({
    responders,
    loading
}) => {


    const getLoadingRow = () => Object.keys(COLUMNS).reduce((a: any, key) => {a[key] = <LoadText height={'22px'} />; return a}, {id: Math.random()})
    const loadingDataSet = () => [...new Array(5)].map(_ => getLoadingRow())


    const getTableData = () => responders.map(u => ({
        id: u.id,
        name: <div className={styles.Name}>{u.name}</div>,
        role: <div className={styles.Role}>Responder</div>,
    }));



    return <section className={styles.ResponderStaff}>
        <Header>
            Responder Staff
        </Header>
        <ScrollTable
            message={responders.length === 0 ? 'No active responders.' : ''}
            className={styles.Table}
            columns={COLUMNS}
            data={loading ? loadingDataSet() : getTableData()}
        />
    </section>
}