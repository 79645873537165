import { Loader } from './../../../../components/shared/loading/Loader';
import { FC, useState } from 'react';
import { useAppData } from '../../../../contexts/AppData';
import { Department } from '../../../../models/department';
import { Api } from '../../../../utils/api';
import { Header } from '../../../shared/header/Header';
import { Select } from '../../../shared/inputs/Select';
import { css } from './../../../../utils/css';
import { UserInfo } from './../../../../models/userInfo';

import styles from './NewPage.module.css';


interface NewPageParams {
    department?: Department,
    responders: UserInfo[],
};

export const NewPage: FC<NewPageParams> = ({ department, responders }) => {
    const [error, setError] = useState<string|undefined>(undefined);
    const [posting, setPosting] = useState(false);
    const {
        selectedSite,
        // providers,
        reasons
    } = useAppData();
    

    const locationsOptions = () => [...selectedSite?.locations.map(l => l.zones.map(z => z.rooms.map(r => (l.name + ' - ' + z.name + ' - ' + r.name
        // {value: l.name + ' - ' + z.name + ' - ' + r.name,
        // id: r.id,
        // roomId: r.id,
        // zonesId: z.id,
        // locationId: l.id,}
    )))).flat(2) ?? [], 'Other'];
    
    const [location, setLocation] = useState<string|null>(null);
    const [freeTextLocation, setFreeTextLocation] = useState<string|null>(null);
    const [provider, setProvider] = useState<{ value: string, id: number }|null>(null);
    const [reason, setReason] = useState<string>('');
    const [freeTextReason, setFreeTextReason] = useState<string|null>(null);

    // useEffect(() => {
    //     if (provider == null) {
    //         setProvider(providers[0] && { value: providers[0].name, id: providers[0].id});
    //     }
    // }, [providers]);

    const resetForm = () => {
        // setLocation(locationsOptions()[0]);
        setLocation(null)
        setFreeTextLocation('');
        setProvider(null);
        setReason('');
        setFreeTextReason('');
    }


    const postData = async () => {

        if (!department) return;

        if (!location || (location === 'Other' && !freeTextLocation)) {
            setError('Location can not be empty');
            return;
        }

        if (!reason || (reason === 'Other' && !freeTextReason)) {
            setError('Reason can not be empty');
            return;
        }

        setError(undefined);
        setPosting(true);

        Api.post('pages', {
            siteId: selectedSite?.id ?? 'null',
            departmentId: department.id,
            // location: typeof location === 'string' ? location : location.value,
            location: location === 'Other' ? freeTextLocation : location,
            providerId: provider?.id,
            reason: reason === 'Other' ? 'Other: ' + freeTextReason : reason,
        })
        .then(res => {
            setPosting(false);
            resetForm();
        })
        .catch(err => {
            setPosting(false);
            setError('There was an issue sending the page');
            console.error('Error posting page', err);
        })
    }
    
    return <section className={styles.NewPage}>
        <Header>
            New Page to {department?.name ?? '?'}
        </Header>
        <form
            className={styles.NewPageForm}
            onSubmit={e => e.preventDefault()}
        >
            {error && <p className={styles.Error}>
                {error}
            </p>}

            <label>
                Location
                <Select
                    type='text'
                    disabled={posting || !department}
                    placeholder='Location'
                    value={location ?? ''}
                    // allowAny={true}
                    options={locationsOptions()}
                    onChange={v => setLocation(v)}
                />
                <input
                    type='text'
                    placeholder='Location'
                    value={freeTextLocation ?? ''}
                    className={css(styles.FreeTextLocation, location === 'Other' && styles.Show)}
                    onChange={e => setFreeTextLocation(e.target.value)}
                />
            </label>

            <label>
                Responsible Provider
                <Select
                    disabled={posting || !department}
                    placeholder='Provider'
                    value={provider}
                    allowEmpty={true}
                    onChange={v => setProvider(v)}
                    options={responders.map(r => ({ value: r.name, id: r.id }))}
                />
            </label>

            <label>
                Reason
                <Select
                    disabled={posting || !department}
                    placeholder='Reason'
                    value={reason}
                    onChange={v => setReason(v)}
                    options={reasons.map(r => r.name)}
                />
                <input
                    type='text'
                    placeholder='Reason'
                    value={freeTextReason ?? ''}
                    className={css(styles.FreeTextReason, reason === 'Other' && styles.Show)}
                    onChange={e => setFreeTextReason(e.target.value)}
                />
            </label>

            <button
                className='primary'
                onClick={() => postData()}
                disabled={posting || !department}
            >{posting ? <Loader color='currentColor' size={4} height={12} /> : 'Send'}</button>
        </form>
    </section>
}