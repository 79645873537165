import { FC } from 'react';
import { useAppData } from '../../../contexts/AppData';
import { Department } from '../../../models/department';
import { css } from '../../../utils/css';
import { DepartmentsButton } from '../department/DepartmentButton';
import { LoadingDepartmentButton } from '../department/LoadingDepartmentButton';
import styles from './DepartmentSelector.module.css';
// import { DEPARTMENTS_ORDERED } from '../../../utils/department';

type DepartmentSelectorParams = {
    value: number[],
    onChange: (d: number[]) => void,
    direction?: DepartmentSelectorDirection,
    mode?: DepartmentSelectorMode,
    loadingIds?: number[],
    className?: string,
};

export enum DepartmentSelectorMode {
    Single,
    Multiple,
}

export enum DepartmentSelectorDirection {
    Column,
    Row,
}

export const DepartmentSelector: FC<DepartmentSelectorParams> = ({
    value,
    onChange,
    direction = DepartmentSelectorDirection.Column,
    mode = DepartmentSelectorMode.Single,
    loadingIds = [],
    className,
}: DepartmentSelectorParams) => {

    function handleClick(d: number) {
        if (mode === DepartmentSelectorMode.Single) {
            onChange([d]);
        } else if (mode === DepartmentSelectorMode.Multiple) {
            //Need shallow clone, deep clone will make matching departments hard...
            const set = [...value];
            const index = set.indexOf(d);
            if (index > -1) set.splice(index, 1);
            else set.push(d);

            onChange(set);
        }
    }

    const {
        departments,
        departmentsLoading,
     } = useAppData();

    return <div className={css(
        styles.DepartmentSelector,
        className,
        direction === DepartmentSelectorDirection.Column && styles.Column,
        direction === DepartmentSelectorDirection.Row && styles.Row
    )}>
        <div className={styles.DepartmentSelectorWrapper}>
            {departmentsLoading && departments.length === 0 && [...new Array(7)].map((_, i) => <LoadingDepartmentButton key={i} />)}
            {departments
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(d => <DepartmentsButton
                active={value.includes(d.id)}
                key={d.id}
                onClick={() => handleClick(d.id)}
                department={d}
                loading={loadingIds.includes(d.id)}
            />)}
        </div>
    </div>
}