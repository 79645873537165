// import { getAccessTokenAsync } from './auth/auth';

import { HubConnectionBuilder } from '@microsoft/signalr';
import { getAccessTokenAsync } from './auth';

const apiBase: string = process.env.REACT_APP_API_URL ?? '';
const webhookBase: string = process.env.REACT_APP_WEBHOOK_URL ?? '';

export const Api = {
    async delete<T>(path: string, data: object): Promise<T> {
        const res = await fetch(pathJoin(apiBase, path), {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(data)
        });

        if (res.status === 200)
            return res.json() as T;
        else return {} as T
    },

    async patch<T>(path: string, data: object): Promise<T> {
        const res = await fetch(pathJoin(apiBase, path), {
            method: 'PATCH',
            headers: {
                'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(data)
        });

        return res.json() as T;
    },

    async put<T>(path: string, data: object): Promise<T> {
        const res = await fetch(pathJoin(apiBase, path), {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(data)
        });

        
        if (res.status === 200)
            return res.json() as T;
        else return {} as T
    },

    async post<T>(path: string, data: object): Promise<T> {
        const res = await fetch(pathJoin(apiBase, path), {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(data)
        });

        return res.json() as T;
    },

    async get<T>(path: string, data: { [key: string]: string } = {}): Promise<T> {
        const res = await fetch(getUrl(path, data), {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                'Accept': 'application/json',
            }
        });

        return res.json() as T;
    },

    async connect() {
        console.log('Creating connection')
        const connection = new HubConnectionBuilder()
            .withUrl(pathJoin(webhookBase, 'app'), {
                accessTokenFactory: getAccessTokenAsync,
                headers: {
                    // 'Authorization': 'Bearer ' + await getAccessTokenAsync(),
                    'Authorization': 'test',
                }
            })
            .withAutomaticReconnect()
            .build();

        return connection;
    }

    // async cache(path, data) {
    //     if (!cache) return await Api.get(path, data);

    //     let url = getUrl(path, data);

    //     let match = await cache.match(url);
        
    //     if (match) return match.json();

    //     let res = await fetch(url, {
    //         method: 'GET',
    //         headers: {
    //             'Authorization': 'Bearer ' + await getAccessTokenAsync()
    //         }
    //     });
        
    //     cache.put(url, res);

    //     return res.json();
    // }
};

const getUrl = (path: string, data: { [key: string]: string }) =>  {
    let url = new URL(pathJoin(apiBase, path));

    for (let param in data) {
        url.searchParams.append(param, data[param]);
    }

    return url.href;
}

export const pathJoin: (...args: string[]) => string = (...args) =>
    (args.length === 1) ? args?.[0]
    : pathJoin(...[(args?.[0]?.toString().endsWith('/') ? args[0].slice(0, -1) : args[0]) + '/' + (args?.[1]?.toString().startsWith('/') ? args[1].slice(1) : args[1]), ...args.splice(2)]);
