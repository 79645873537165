import { FC, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppData } from '../../../contexts/AppData';
import { css } from '../../../utils/css';
import { useIsMobile } from '../../../utils/hooks';
import { Icon } from '../../shared/icons/Icon';
import { SiteSelector } from '../../shared/inputs/SiteSelector';
import { ReactComponent as HomeSvg } from './../../../assets/icons/home.svg';
import { ReactComponent as AccountSvg } from './../../../assets/icons/login.svg';
import { ReactComponent as QuickPageSvg } from './../../../assets/icons/quick-page.svg';
import { ReactComponent as SettingsSvg } from './../../../assets/icons/settings.svg';
import { MobileNavPage } from './MobileNavPage';
import { SignedInDepartments } from './SignedInDepartments';

import styles from './HeaderNav.module.css';

export const HeaderNav: FC = () => {

    const [showAccount, setShowAccount] = useState(false);
    const isMobile = useIsMobile();
    const {
        selectedSite,
        setSelectedSite,
    } = useAppData();

    return <header className={styles.HeaderNav}>
        {
            !isMobile &&
            <>
                <NavLink
                    to='/mypages'
                    className={({ isActive }) => css(styles.Icon, isActive && styles.Active)}>
                    <HomeSvg />
                </NavLink>
                <NavLink
                    to='/quickpages'
                    className={({ isActive }) => css(styles.Icon, isActive && styles.Active)}>
                    <QuickPageSvg />
                </NavLink>
                <NavLink
                    to='/settings'
                    className={({ isActive }) => css(styles.Icon, styles.Settings, isActive && styles.Active)}>
                    <SettingsSvg />
                </NavLink>
                <div className={css(styles.Icon, styles.SiteSelector)}>
                    <SiteSelector value={selectedSite} onChange={setSelectedSite} />
                </div>
            </>
        }
        
        {!isMobile && 
            <div className={styles.Logo}>
                {selectedSite && <Icon src={selectedSite.icon} mask={false} />}
            </div>
        }

        {isMobile && <>
                <div className={styles.Departments} onClick={() => setShowAccount(true)}>
                    <SignedInDepartments displayOnly />
                </div>

                <MobileNavPage
                    shown={showAccount}
                    onClose={() => setShowAccount(false)}
                />

                <NavLink
                    to='/mypages'
                    className={({ isActive }) => css(styles.Icon, styles.MyPagesToggle, isActive && styles.Active)}>
                    <HomeSvg />
                </NavLink>
                <NavLink
                    to='/settings'
                    className={({ isActive }) => css(styles.Icon, styles.SettingsToggle, isActive && styles.Active)}>
                    <SettingsSvg />
                </NavLink>

                <button
                    className={css(styles.Account, styles.Icon)}
                    title='Account'
                    onClick={() => setShowAccount(true)}
                >
                    <AccountSvg />
                </button>
            </>
        }
    </header>
}