import { useEffect, useRef, useState } from 'react';


const obv = new ResizeObserver(entries => {
    for (let i = 0; i < entries.length; i++) {
        map.get(entries[i].target)?.(entries[i]);
    }
})

const map = new WeakMap<Element, (e: ResizeObserverEntry) => void>();


export const useSize = () => {

    const [size, setSize] = useState({outerWidth: 0, outerHeight: 0, innerWidth: 0, innerHeight: 0});
    const ref = useRef(null);

    useEffect(() => {

        if (ref.current) {
            obv.observe(ref.current);

            map.set(ref.current, e => {
                if (e.borderBoxSize && e.contentBoxSize) {
                    const bb = Array.isArray(e.borderBoxSize) ? e.borderBoxSize[0] : e.borderBoxSize;
                    const cb = Array.isArray(e.contentBoxSize) ? e.contentBoxSize[0] : e.contentBoxSize;
                    setSize({outerWidth: cb.inlineSize, outerHeight: cb.blockSize, innerWidth: cb.inlineSize, innerHeight: bb.blockSize});
                }
            })
        }

        var el = ref.current;


        return () => {
            if (el) {
                obv.unobserve(el);
                map.delete(el);
            }
        };
    }, []);

    return { ref, size };
}