import { useEffect, useState } from 'react';
import { Header } from '../../shared/header/Header';
import { DepartmentSelector, DepartmentSelectorDirection, DepartmentSelectorMode } from '../../shared/inputs/DepartmentSelector';
import { ReactComponent as SettingsIcon } from './../../../assets/icons/settings.svg';
import { useAppData } from './../../../contexts/AppData';
import { Table } from '../../shared/table/Table';
import { Select } from '../../shared/inputs/Select';
import { css } from './../../../utils/css';
import { Api } from './../../../utils/api';
import { Loader } from '../../shared/loading/Loader';
import { UserInfo } from './../../../models/userInfo';
import { Icon } from '../../shared/icons/Icon';
import { useIsMobile } from './../../../utils/hooks';

import styles from './Settings.module.css';
import Toggle from 'react-toggle';
import { useNavigate } from 'react-router';

const QUICKPAGES_COLUMNS = {
    location: {
        label: 'Location',
        size: 'minmax(0, 1fr)',
    },
    action: {
        cellClassName: styles.ActionCell,
        label: '',
        size: '150px',
    },
}


const RESPONDER_COLUMNS = {
    responder: {
        label: 'Responder',
        size: 'minmax(0, 1fr)',
    },
    departments: {
        label: 'Departments',
        size: 'minmax(0, 1fr)',
    },
    action: {
        cellClassName: styles.ActionCell,
        label: '',
        size: '150px',
    },
}


export const Settings = () => {

    const [defaultDepartmentsLocal, setDefaultDepartmentsLocal] = useState<number[]>([]);
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    const {
        userInfo,
        setUserInfoAsync,
        departments,
        selectedSite,
        sites,
        addQuickPageAsync,
        removeQuickPageAsync,
    } = useAppData();

    const [savingDepartments, setSavingDepartments] = useState(false);

    const [newQuickPageLocation, setNewQuickPageLocation] = useState('');
    const [newQuickPageFreeTextLocation, setNewQuickPageFreeTextLocation] = useState('');
    const [addingPage, setAddingPage] = useState(false);
    const [deletingPages, setDeletingPages] = useState<number[]>([]);
    const [soundOverride, setSoundOverride] = useState<boolean>(userInfo?.soundOverride ?? false);
    const [defaultSite, setDefaultSite] = useState<{ value: string; id: number | undefined; }>({value: sites.find(s => s.id === userInfo?.defaultSiteId)?.name ?? '', id: userInfo?.defaultSiteId});

    const locationsOptions = () => [...selectedSite?.locations.map(l => l.zones.map(z => z.rooms.map(r => (l.name + ' - ' + z.name + ' - ' + r.name)))).flat(2) ?? [], 'Other'];

    const addQuickPage = async () => {
        setAddingPage(true);

        await addQuickPageAsync({
            id: -1,
            location: newQuickPageLocation === 'Other' ? newQuickPageFreeTextLocation : newQuickPageLocation,
        });

        setNewQuickPageLocation('');
        setNewQuickPageFreeTextLocation('');

        setAddingPage(false);
    }

    const removeQuickPage = async (id: number) => {
        setDeletingPages(prevDeletingPages => [...prevDeletingPages, id]);

        await removeQuickPageAsync(id);

        setDeletingPages(prevDeletingPages => [...prevDeletingPages.filter(x => x !== id)]);
    }


    const saveDepartments = async () => {
        setSavingDepartments(true);
        await setUserInfoAsync({
            defaultDepartments: defaultDepartmentsLocal,
            soundOverride: soundOverride,
            defaultSiteId: defaultSite.id,
        });

        // If mobile, then go back to the home screen
        navigate('/');

        setSavingDepartments(false);
    }

    const cancelDepartmentsChanges = () => {
        setSoundOverride(userInfo?.soundOverride ?? false);
        setDefaultSite({value: sites.find(s => s.id === userInfo?.defaultSiteId)?.name ?? '', id: userInfo?.defaultSiteId});
        setDefaultDepartmentsLocal(userInfo?.defaultDepartments ?? []);
    }

    useEffect(() => {
        setDefaultDepartmentsLocal(userInfo?.defaultDepartments ?? [])
    }, [userInfo, departments])


    const getLocalQuickPagesRows = () => [...(userInfo?.quickPages ?? []).map(qp => ({
        id: qp.id,
        location: <>{qp.location}</>,
        action: <button
            disabled={deletingPages.includes(qp.id)}
            onClick={() => removeQuickPage(qp.id)}
            className={styles.Action}
        >
            {deletingPages.includes(qp.id) ? <Loader color='currentColor' size={4} height={12} /> : 'Remove'}
        </button>,
    })),
    {
        id: -1,
        location: <>
            <Select
                type='text'
                disabled={false}
                placeholder='Location'
                value={newQuickPageLocation ?? ''}
                allowAny={false}
                options={locationsOptions()}
                onChange={v => setNewQuickPageLocation(v ?? '')}
            />
             <input
                type='text'
                placeholder='Location'
                value={newQuickPageFreeTextLocation ?? ''}
                className={css(styles.FreeTextLocation, newQuickPageLocation === 'Other' && styles.Show)}
                onChange={e => setNewQuickPageFreeTextLocation(e.target.value)}
            />
        </>,
        action: <button
            onClick={() => addQuickPage()}
            disabled={!newQuickPageLocation || addingPage}
            className={css(styles.Action, styles.AddQuickPageAction, 'primary')}
        >{addingPage ? <Loader color='currentColor' size={4} height={12} /> : 'Add'}</button>
    }];

    const [removingResponders, setRemovingResponders] = useState<number[]>([]);
    const [loadingResponders, setLoadingResponders] = useState(false);
    const [responders, setResponders] = useState<UserInfo[]>([]);


    useEffect(() => {
        getResponders();
    }, []);

    const getResponders = () => {
        setLoadingResponders(true);
        Api.get<UserInfo[]>('users/active', {
            site: selectedSite?.id.toString()!,
        }).then(json => {
            setLoadingResponders(false);
            setResponders(json);
        }).catch(ex => {
            setLoadingResponders(false);
        })
    }

    const getResponderColumns = () => responders.map(r => ({
        id: r.id,
        responder: r.name,
        departments: <div className={styles.DepartmentsIcons}>
            {r.activeDepartments.map(d => <Icon key={d} src={departments.find(dd => dd.id === d)?.icon} />)}
        </div>,
        action: <button
            disabled={removingResponders.includes(r.id)}
            onClick={() => removeResponder(r.id)}
            className={styles.Action}
        >
            {removingResponders.includes(r.id) ? <Loader color='currentColor' size={4} height={12} /> : 'Remove'}
        </button>
    }));

    const removeResponder = async (id: number) => {
        setRemovingResponders(prev => [...prev, id]);

        await Api.put('user/sign-out?userId=' + id, {});

        getResponders();
        setRemovingResponders(prev => [...prev.filter(x => x !== id)]);
    }


    return <section className={styles.Settings}>
        <Header icon={<SettingsIcon />}>
            <b>Settings</b>
        </Header>

        <section className={styles.SettingsContent}>
            {isMobile && <>
                <section className={styles.SettingsSection}>
                    {/* <label>
                        Page Sound
                        <Toggle
                            className={styles.Toggle}
                            checked={soundOverride}
                            name='soundOverride'
                            icons={false}
                            onChange={(e) => setSoundOverride(e.target.checked)}
                        />
                    </label> */}
                    <label className={styles.Site}>
                        Default Site

                        <Select
                            options={sites.map(s => ({value: s.name, id: s.id}))}
                            value={defaultSite}
                            onChange={v => setDefaultSite(v)}
                        />
                    </label>
                    <label>
                        Default Departments
                        <DepartmentSelector
                            className={styles.Departments}
                            value={defaultDepartmentsLocal}
                            onChange={setDefaultDepartmentsLocal}
                            mode={DepartmentSelectorMode.Multiple}
                            direction={DepartmentSelectorDirection.Row}
                        />
                    </label>
                    <div className={styles.SaveDepartmentsBtnRow}>
                        <button
                            disabled={savingDepartments}
                            className={css(styles.SaveDepartmentsBtn)}
                            onClick={() => cancelDepartmentsChanges()}
                        >
                            {savingDepartments ? <Loader color='currentColor' size={4} height={12} /> : 'Cancel'}
                        </button>
                        <button
                            disabled={savingDepartments}
                            className={css(styles.SaveDepartmentsBtn, 'primary')}
                            onClick={() => saveDepartments()}
                        >
                            {savingDepartments ? <Loader color='currentColor' size={4} height={12} /> : 'Save'}
                        </button>
                    </div>
                </section>
            </>}

            {!isMobile && <>
                <section className={styles.SettingsSection}>
                    <label>
                        Quick Pages
                    </label>
                    <Table
                        className={css(styles.QuickPages, styles.RoundCorners)}
                        columns={QUICKPAGES_COLUMNS}
                        data={getLocalQuickPagesRows()}
                    />
                    <br />
                </section>

                <section className={styles.SettingsSection}>
                    <label>
                        Admin Options
                    </label>
                    <Table
                        className={styles.RoundCorners}
                        columns={RESPONDER_COLUMNS}
                        message={responders.length === 0 ? 'No Active Responders' : undefined}
                        data={getResponderColumns()}
                    />
                </section>
            </>}
        </section>
    </section>
}