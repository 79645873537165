import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { css } from '../../../utils/css';
import { useAppData } from '../../../contexts/AppData';
import { DepartmentsButton } from './DepartmentButton';
import styles from './DepartmentNav.module.css';
import { LoadingDepartmentButton } from './LoadingDepartmentButton';

export const DepartmentNav: FC = () => {

    const {
        departments,
        departmentsLoading,
     } = useAppData();

    return <div className={css(styles.DepartmentNav)}>
        <div className={styles.DepartmentNavWrapper}>
            {departmentsLoading && departments.length === 0 && [...new Array(7)].map((_, i) => <LoadingDepartmentButton key={i} strength={3} />)}
            {departments
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(d => <NavLink
                key={d.id}
                className={styles.Button}
                to={'/departments/' + d.id}
            >
                {({ isActive }) => <DepartmentsButton
                        active={isActive}
                        department={d}
                    />
                }
            </NavLink>)}
        </div>
    </div>
}