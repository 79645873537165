import { FC } from 'react';
import { css } from '../../../utils/css';
import { ReactComponent as ArrowSvg } from './../../../assets/icons/arrow.svg';

import styles from './ArrowBtn.module.css';


type ArrowBtnParams = {
    direction: ArrowDirection,
    [key: string]: any,
}

export enum ArrowDirection {
    Up,
    Down,
    Left,
    Right,
}

const DIRECTION_MAP = {
    [ArrowDirection.Up]:    { css: styles.Up,       title: 'Up Arrow'       },
    [ArrowDirection.Down]:  { css: styles.Down,     title: 'Down Arrow'     },
    [ArrowDirection.Left]:  { css: styles.Left,     title: 'Left Arrow'     },
    [ArrowDirection.Right]: { css: styles.Right,    title: 'Right Arrow'    },
}

export const ArrowBtn: FC<ArrowBtnParams> = ({direction, className = '', ...props}) => {
    
    return <button
        className={css(styles.ArrowBtn, DIRECTION_MAP[direction].css, className)}
        title={DIRECTION_MAP[direction].title}
        {...props}
    >
        <ArrowSvg />
    </button>
}