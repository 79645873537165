import { FC } from 'react'
import { css } from '../../../utils/css';

import styles from './Table.module.css';

export interface TableParams {
    data: { id: (string | number), [key: string]: string | number | JSX.Element }[]
    columns: { [key: string]: (string | {
        label: string,
        size: string,
        headingClassName?: string,
        cellClassName?: string,
    })},
    message?: string|React.ReactNode|React.ReactNode[],
    [key: string]: any,
}

export const Table: FC<TableParams> = ({
    data = [],
    columns,
    className = false,
    style = {},
    message,
    ...props
}: TableParams) => {
    return <div
        className={css(styles.Table, className)}
        style={{
            '--template': `${Object.values(columns).map(i => typeof i === 'string' ? 'minmax(0, 1fr)' : i.size).join(' ')}`,
            ...style,
        } as React.CSSProperties}
        {...props}
    >
        <div className={css(styles.Row, styles.Header, 'header', 'row')}>
            {Object.entries(columns).map(([key, labelOrObj]) => (
                <div key={key} className={css(styles.Cell, 'cell', typeof labelOrObj !== 'string' && labelOrObj.headingClassName)}>
                    {(typeof labelOrObj === 'string') ? labelOrObj : labelOrObj.label}
                </div>
            ))}
        </div>

        <div className={css(styles.Body, 'body')}>
            {message && <div className={css(styles.Cell, 'cell', 'message', styles.Message)}>
                {message}
            </div>}
            {!message && data &&
                data.map(({ id, ...rest }) => (<div key={id} className={css(styles.Row, 'row')}>
                    {Object.entries(columns).map(([key, labelOrObj]) => (
                        <div key={key} className={css(styles.Cell, 'cell', typeof labelOrObj !== 'string' && labelOrObj.cellClassName)}>
                            {rest[key]}
                        </div>
                    ))}
                </div>))
            }
        </div>
    </div>
}