export const msalConfig = {
    auth: {
        clientId: 'cd95c9cc-94aa-4b8b-b208-b83a08f8c517',
        authority: 'https://login.microsoftonline.com/7cc8b384-9f8b-4400-8cc1-8a71e5a7b117',
        redirectUri: window.location.protocol + '//' + window.location.host,
        // prompt: 'admin_consent'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    }
};
  
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        'api://56eaa955-865f-44d3-9362-972232163681/access_as_user',
    ]
};