import { KeyboardEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';
import { css } from '../../../utils/css';
import { useIsMounted } from '../../../utils/hooks';
import { useSize } from '../../../utils/useSize';
import { ArrowBtn, ArrowDirection } from './ArrowBtn';
import styles from './Select.module.css';

type SelectValue = string | ({value: string, disabled?: boolean, [key: string]: any});

type SelectParams<T extends SelectValue, U extends boolean = false, E extends boolean = false> = {
    value: (U extends true ? T|null : E extends true ? T|null : T),
    //When allow any is enabled, this can return a string, the given type, or null. Otherwise, it will only return the given type.
    onChange: (v: (U extends true ? T|string|null : E extends true ? T|null : T), i?: number) => void,
    options: T[],
    allowSearch?: boolean,
    allowAny?: U,
    allowEmpty?: E,
    disabled?: boolean,
    type?: string,
    loading?: boolean,
    onSearch?: (v: string) => void,
    onSearchStatus?: (v: boolean) => void,
    [key: string]: any,
}

export const Select = <T extends SelectValue, U extends boolean = false, E extends boolean = false>({
    value,
    onChange,
    options,
    allowSearch = true,
    allowAny,
    allowEmpty,
    disabled = false,
    loading = false,
    onSearch = (v) => {},
    onSearchStatus = (v) => {},
    type = 'text',
    ...props
}: SelectParams<T, U, E>) => {

    const getStringValue = (v: SelectValue|null): string => typeof v === 'string' ? v : v?.value ?? '';
    const getDisabled = (v: SelectValue|null): boolean => !!(disabled || (typeof v !== 'string' && v?.disabled));
    const getId = (v: SelectValue|null): string => typeof v === 'string' ? v : v?.id;

    const [animate, setAnimate] = useState(false);
    const [searchValue, setSearchValue] = useState(getStringValue(value));
    const [isSearching, setIsSearching] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [highlightIndex, setHighlightedIndex] = useState<number|null>(null);
    const arrowRef = useRef<HTMLDivElement>(null);
    const highlightRef = useRef<HTMLButtonElement>(null);
    const isMounted = useIsMounted();
    const { ref, size } = useSize();

    const [dropPosition, setDropPosition] = useState({top: -100000, left: 0});

    const results = () => options
        .filter(o => !isSearching || getStringValue(o).toLowerCase().includes((searchValue ?? '').toLowerCase()))

    useEffect(() => {
        setSearchValue(getStringValue(value));
    }, [value]);

    useEffect(() => {
        if (expanded) {
            updateDropPosition();
            setIsSearching(false);
        } 
    }, [expanded]);

    const updateDropPosition = () => {
        if (!ref.current) return;
        
        const rect = (ref.current as HTMLDivElement).getBoundingClientRect();
        setDropPosition({
            top: rect.bottom,
            left: rect.left,
        });
    }

    const handleBlur = (e: any) => {

        //On Blur is called when the focus changes within the selector.
        //This checks to make sure the focused element is outside it.
        if (!e.currentTarget?.contains(e.relatedTarget)) {
            if (allowAny ?? false) {
                onChange(searchValue as U extends true ? T|string|null : T);
            } else if (searchValue === '' && (allowEmpty ?? false)) {
                onChange(null as U extends true ? T|string|null : T);
            } else {
                const option = options.find(o => getStringValue(o) === searchValue.toLowerCase());
                if (option === undefined)
                    setSearchValue(getStringValue(value));
                else
                    onChange(option);
            }

            setHighlightedIndex(null);

            setIsSearching(false);
            setExpanded(false);
        }
    }

    const handleFocus = (e: any) => {
        if (disabled) return;

        //Make sure the focus isn't on the arrow
        if (!arrowRef.current?.contains(e.target))
            setExpanded(true);
    }

    const handleSearch = (e: { currentTarget: { value: SetStateAction<string>; }; }) => {
        setExpanded(true);
        setIsSearching(true);
        setSearchValue(e.currentTarget.value);
    }

    const handleArrowClick = () => {
        if (disabled) return;
        setExpanded(!expanded);
    }


    const handleOptionClick = (o: T, i: number) => {
        if (getDisabled(o)) return;
        setIsSearching(false);
        setExpanded(false);
        onChange(o, i);
    }

    const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (highlightIndex !== null) {
                let r = results()[highlightIndex];
                if (typeof r !== 'string' && r.disabled) return;

                onChange(r, highlightIndex);
            } else if (allowAny === true) {
                onChange(searchValue as U extends true ? T|string|null : T, undefined);
            } else if (searchValue === '' && (allowEmpty ?? false)) {
                onChange(null as U extends true ? T|string|null : T, undefined);
            } else {
                setSearchValue('');
            }

            setExpanded(false);
            setHighlightedIndex(null);
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setHighlightedIndex(((highlightIndex ?? -1) + 1) % results().length);
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setHighlightedIndex(((highlightIndex ?? 0) - 1 + results().length) % results().length);
        }
    }

    useEffect(() => {
        onSearch(searchValue);
        onSearchStatus(isSearching);
        if (isSearching && results().length > 0) {
            setHighlightedIndex(0);
        } else {
            setHighlightedIndex(null);
        }
    }, [searchValue, isSearching]);

    useEffect(() => {
        if (highlightRef.current)
            highlightRef.current.scrollIntoView(false);
    }, [highlightIndex]);

    useEffect(() => {
        //Don't animate drop down on load
        window.requestAnimationFrame(() => {
            setAnimate(true)
        });
    }, []);

    return <div
        tabIndex={disabled ? undefined : -1}
        ref={ref}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleInputKeyDown}
        className={css(
            styles.Select,
            !animate && styles.NoAnimate,
            isMounted && styles.Mounted,
            expanded && styles.Expanded,
            disabled && styles.Disabled
        )}
    >
        <div className={styles.InputWrapper}>
            <input
                type={type}
                value={getStringValue(searchValue) ?? ''}
                className={css(styles.Input, !disabled && !allowSearch && styles.NotDisabled)}
                onInput={handleSearch}
                disabled={disabled || !allowSearch}
                autoComplete={Math.random().toString()}
                {...props}
            />
        </div>

        <div
            tabIndex={-1}
            ref={arrowRef}
            className={styles.ArrowWrapper}
        >
            <ArrowBtn
                disabled={disabled}
                className={styles.Arrow}
                direction={expanded ? ArrowDirection.Up : ArrowDirection.Down}
                onClick={handleArrowClick}
            />
        </div>

        <div className={styles.Drop} style={{
            width: size.innerWidth + 'px',
            top: dropPosition.top + 'px',
            left: dropPosition.left + 'px',
        }}>


            {results().map((o, i) => (
                <button
                    ref={i === highlightIndex ? highlightRef : undefined}
                    type='button'
                    disabled={getDisabled(o)}
                    key={getId(o)}
                    className={css(
                        styles.Option,
                        i === highlightIndex && styles.Highlighted,
                        getId(o) === getId(value) && styles.Selected,
                    )}
                    onClick={() => handleOptionClick(o, i)}
                >{getStringValue(o)}</button>
            ))}
            {results().length === 0 ? <button
                    type='button'
                    disabled={disabled}
                    className={css(
                        styles.Option,
                        styles.Locked
                    )}
            >No results</button> : ''}
        </div>
    </div>
}