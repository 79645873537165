import { FC } from 'react';
import { css } from '../../../utils/css';
import styles from './Header.module.css';

export const Header: FC<{
    children: React.ReactNode,
    icon?: React.ReactNode,
    className?: string,
    [key: string]: any,
}> = ({
    children,
    icon,
    className,
    ...props
}) => {
    return <header className={css(styles.Header, className)} {...props}>
        <div className={styles.Text}>
            {children}
        </div>
        {icon && <div className={styles.Icon}>{icon}</div>}
    </header>
}