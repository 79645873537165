
import { FC } from 'react';
import { css } from '../../../utils/css';
import { LoadText } from '../loading/LoadText';
import styles from './LoadingDepartmentButton.module.css';


interface LoadingDepartmentsButtonParams {
    strength?: number,
    [key: string]: any,
};

export const LoadingDepartmentButton: FC<LoadingDepartmentsButtonParams> = ({
    strength,
    className,
    ...props
}) => {
    return <button
        className={css(className, styles.DepartmentButton)}
        title='Loading department'
        {...props}
    > 
        <LoadText width='50%' height='50%' min={100} max={1000} strength={strength} />
    </button>
}