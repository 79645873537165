import { useAppData } from '../../../contexts/AppData';
import React, { FC } from 'react';
import { css } from '../../../utils/css';
import { DepartmentSelector, DepartmentSelectorMode } from '../../shared/inputs/DepartmentSelector';
import { Select } from '../../shared/inputs/Select';
import { ReactComponent as XSvg } from './../../../assets/icons/x.svg';

import styles from './MobileNavPage.module.css';
import { useMsal } from '@azure/msal-react';

export type MobileNavPageParams = {
    shown: boolean,
    onClose: () => void,
}

export const MobileNavPage: FC<MobileNavPageParams> = ({
    shown,
    onClose,
}) => {

    const {
        selectedSite,
        setSelectedSite,
        setSignedInDepartments,
        signOutUserAsync,
        userInfo,
        sites,
    } = useAppData();

    const masl = useMsal();

    const signOutAsync = async () => {
        await signOutUserAsync();
        masl.instance.logout();
    }
    
    return <div className={css(styles.MobileNavPage, shown && styles.Shown)}>

        <button className={styles.X} onClick={onClose} title='Close'><XSvg/></button>

        <h1>{userInfo?.name}</h1>


        <label className={styles.Site}>
            Current Site

            <Select
                options={sites.map(s => s.name)}
                value={selectedSite?.name ?? ''}
                onChange={v => setSelectedSite(sites.find(s => s.name === v) ?? sites[0])}
            />
        </label>

        <div className={styles.SignedInDepartments}>
            <h1>Signed In Departments</h1>
            <DepartmentSelector
                value={userInfo?.activeDepartments ?? []}
                onChange={setSignedInDepartments}
                mode={DepartmentSelectorMode.Multiple}
            />
        </div>

        <button className={css('primary', styles.SignoutBtn)} onClick={signOutAsync}>Sign Out</button>
    </div>
}