import { Site } from '../models/site';

type ThemeType = {
    [key: string]: {
        [key: string]: string,
    }
}

const themes: ThemeType = {
    1: {
        '--primary-010': 'hsl(208, 36%, 99%)',
        '--primary-100': 'hsl(208, 36%, 90%)',
        '--primary-200': 'hsl(208, 36%, 80%)',
        '--primary-300': 'hsl(208, 36%, 70%)',
        '--primary-400': 'hsl(208, 36%, 60%)',
        '--primary-500': 'hsl(208, 36%, 50%)',
        '--primary-600': 'hsl(208, 36%, 40%)',
        '--primary-700': 'hsl(208, 36%, 30%)',
        '--primary-800': 'hsl(208, 36%, 20%)',
        '--primary-900': 'hsl(208, 36%, 10%)',
    },
    2: {
        '--primary-010': 'hsl(339, 50%, 99%)',
        '--primary-100': 'hsl(339, 55%, 86%)',
        '--primary-200': 'hsl(339deg 37.99% 82.22%)',
        '--primary-300': 'hsl(339deg 44.78% 67.2%)',
        '--primary-400': 'hsl(339deg 50.76% 64.82%)',
        '--primary-500': 'hsl(339deg 68.25% 31.36%)',
        '--primary-600': 'hsl(339deg 86.51% 9.71%)',
        '--primary-700': 'hsl(339, 92%, 11%)',
        '--primary-800': 'hsl(339, 94%, 11%)',
        '--primary-900': 'hsl(339, 94%, 10%)',
    },
}

export function changeTheme(l?: Site) {
    const theme = themes[parseInt(l?.id.toString() ?? '1')] ?? themes['1'];

    Object
        .entries(theme)
        .forEach(([k, v]) => document.documentElement.style.setProperty(k, v));

    // Update the meta theme color tag...
    const metaThemeEl = document.querySelector('meta[name="theme-color"]');

    if (metaThemeEl) metaThemeEl.setAttribute('content', theme['--primary-500']);
}