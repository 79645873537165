import { FC } from 'react';

import { ReactComponent as BehavioralHealthSvg } from './../../../assets/icons/departments/behavioral-health.svg';
import { ReactComponent as FacilitiesSvg } from './../../../assets/icons/departments/facilities.svg';
import { ReactComponent as GroupEightSvg } from './../../../assets/icons/departments/group-eight.svg';
import { ReactComponent as HealthCoachSvg } from './../../../assets/icons/departments/health-coach.svg';
import { ReactComponent as LabSvg } from './../../../assets/icons/departments/lab.svg';
import { ReactComponent as LanguageSomaliSvg } from './../../../assets/icons/departments/language-somali.svg';
import { ReactComponent as LanguageSpanishSvg } from './../../../assets/icons/departments/language-spanish.svg';
import { ReactComponent as LanguageVietnameseSvg } from './../../../assets/icons/departments/language-vietnamese.svg';
import { ReactComponent as MaintenanceSvg } from './../../../assets/icons/departments/maintenance.svg';
import { ReactComponent as SupplySvg } from './../../../assets/icons/departments/supply.svg';
import { ReactComponent as SiouxlandSvg } from './../../../assets/siouxland-logo.svg';
import { ReactComponent as IowaHealthSvg } from './../../../assets/iowa-health-plus-logo.svg';
import { EmbeddedSvg } from './EmbeddedSvg';
import styles from './Icon.module.css';
import { css } from './../../../utils/css';

const ICON_MAP: {
    [key: string]: React.FunctionComponent,
} = {
    'behavioral-health.svg': BehavioralHealthSvg,
    'facilities.svg': FacilitiesSvg,
    'group-eight.svg': GroupEightSvg,
    'health-coach.svg': HealthCoachSvg,
    'lab.svg': LabSvg,
    'language-somali.svg': LanguageSomaliSvg,
    'language-spanish.svg': LanguageSpanishSvg,
    'language-vietnamese.svg': LanguageVietnameseSvg,
    'maintenance.svg': MaintenanceSvg,
    'supply.svg': SupplySvg,
    'siouxland-logo.svg': SiouxlandSvg,
    'iowa-health-plus-logo.svg': IowaHealthSvg,
}

export const Icon: FC<{
    mask?: boolean,
    src?: string,
    [key: string]: any,
}> = ({
    mask = true,
    src,
    ...props
}) => {
        let MappedIcon = ICON_MAP[src ?? 'default'];
        const { style, className, ...others } = props;
        return MappedIcon
            ? <MappedIcon {...props} />
            : mask
                ?
                <div
                    style={{
                        '--mask-url': `url(${src})`,
                        ...style
                    } as React.CSSProperties}
                    className={css(styles.MaskedIcon, 'svg-mimic', className)}
                    {...others}
                />
                : <img src={src} alt="Icon" {...props} />
    }