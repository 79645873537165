import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBhFdDJK---Wa0a5ZrbpEIECpe3y_ZnHSk",
    authDomain: "paging-project-4010e.firebaseapp.com",
    projectId: "paging-project-4010e",
    storageBucket: "paging-project-4010e.appspot.com",
    messagingSenderId: "661584381077",
    appId: "1:661584381077:web:66437e32a785f83cba879c",
    measurementId: "G-Q8QJJJC18D"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);


// Add the public key generated from the console here.

export const getTokenAsync = async () => {
    try {
        return await getToken(messaging, {vapidKey: 'BOjfMuUyhwLj2ZcKN0kaIVLKbC3M-amYPQRBLe0UDa_QMQgj_-mEE_UNu4yiL_ga-qEPL6WXLxqlr6MuFHZOzBg'});
    } catch (ex) {
        console.error('Error getting firebase token', ex);
        return undefined;
    }
}

export function requestPermission() {
    console.log('Requesting notification permission...');
    if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                console.log('Notification permission is granted.');
            } else {
                console.error('Notification permission are not granted. The web app will be unable alert properly without them.')
                // alert('The app cannot function without allowing notifications.');
            }
        });
    }
}