import { FC, useEffect, useState } from 'react';
import { Dropdown, DropdownAlignment } from '../../shared/dropdown/Dropdown';
import { Icon } from '../../shared/icons/Icon';
import { DepartmentSelector, DepartmentSelectorDirection, DepartmentSelectorMode } from '../../shared/inputs/DepartmentSelector';
import { useAppData } from '../../../contexts/AppData';

import styles from './SignedInDepartments.module.css';

export const SignedInDepartments: FC<{
    displayOnly?: boolean,
}> = ({ displayOnly = false }) => {
    const [expanded, setExpanded] = useState(false);

    const {
        departments,
        userInfo,
        setSignedInDepartments,
    } = useAppData();
    
    return <Dropdown
        expanded={expanded}
        onChange={setExpanded}
        width='500px'
        alignment={DropdownAlignment.Left}
        dropContents={
            <DepartmentSelector
                className={styles.DepartmentSelector}
                value={userInfo?.activeDepartments ?? []}
                onChange={setSignedInDepartments}
                direction={DepartmentSelectorDirection.Row}
                mode={DepartmentSelectorMode.Multiple}
            />}
    >
        <button className={styles.SignedInDepartments} onClick={() => { if (!displayOnly) setExpanded(!expanded) }}>
            <div className={styles.SignedInDepartmentsText}>
                Signed in as
            </div>

            <div className={styles.SignedInDepartmentsIcons}>
                {(userInfo?.activeDepartments ?? []).map(d => <Icon key={d} src={departments.find(dd => dd.id === d)?.icon} />)}
            </div>
        </button>
       
    </Dropdown>
}